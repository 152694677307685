<template>
  <div>
    <div :class="isLoading ? 'hidden' : 'block'" class="p-4 space-y-4 h-full">
      <div v-if="User !== null" class="w-full flex sm:flex-row flex-col gap-4">
        <div v-if="User.role != 'admin' && year != null"
          class="year-section sm:w-4/6 w-full h-40 text-center text-white flex justify-center items-center">
          <p class="">{{ year }}</p>
          <p v-if="year == 1" class="ordinal">ère</p>
          <p v-else class="ordinal">ème</p>
          <p class="text-5xl mx-2">Année</p>
          <!--<img src="../../assets/bg-year-section.png" alt="">-->
        </div>
        <div v-else-if="User.role != 'admin' && year == null"
          class="sm:w-4/6 w-full h-40 bg-gradient-to-r to-indigo-400 from-red-400 rounded-lg shadow-md text-center text-white flex justify-center items-center">
          <p class="text-2xl mx-2">Veuillez sélectionner votre année en cours</p>
        </div>
        <div v-else
          class="sm:w-4/6 w-full h-40 bg-gradient-to-r to-indigo-400 from-red-400 rounded-lg shadow-md text-center text-white flex justify-center items-center">
          <p class="text-5xl mx-2">Administrateur</p>
        </div>
        <div class="flex items-center sm:w-2/6 gap-4">
          <div v-if="User.role != 'admin'" class="statistic-section sm:w-1/2 w-full h-40 bg-white">
            <span class="icon">
              <QuestionStatIcon />
            </span>
            <p class="text">Questions</p>
            <p class="number">{{ countQuestions }}</p>
          </div>
          <div v-if="User.role != 'admin'" class="statistic-section sm:w-1/2 w-full h-40 bg-white">

            <span class="icon">
              <QuestionStatIcon />
            </span>
            <p class="text">Modules</p>
            <p class="number">{{ modules }}</p>

          </div>
        </div>

        <div v-if="User.role == 'admin'"
          class="sm:w-1/6 w-full h-40 bg-white border shadow-lg rounded-lg text-center flex justify-center items-center">
          <p class="text-2xl mx-2">
            <span class="w-full flex justify-center">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                stroke="currentColor" class="h-8 w-8">
                <path
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </span>
            <span v-if="countStudents < 10" class="font-bold">0{{ countStudents }} </span><span v-else
              class="font-bold">{{ countStudents }} </span><br> Étudiants
          </p>
        </div>
        <div v-if="User.role == 'admin'"
          class="sm:w-1/6 w-full h-40 bg-white border shadow-lg rounded-lg text-center flex justify-center items-center">
          <div class="mx-2">
            <!-- <span class="w-full flex justify-center">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                stroke="currentColor" class="h-8 w-8">
                <path
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </span> -->
            <span class="w-full flex justify-center">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                </path>
              </svg>
            </span>
            <div v-if="countLogged < 10">
              <span class="font-bold text-base">Date : </span><br><span class="text-base">{{ dateLogged }}</span><br>
              <span class="font-bold text-base">Utilisateurs : </span><span class="text-base">0{{ countLogged }}</span>
            </div>

            <div v-else>
              <span class="font-bold text-base">Date : </span><br><span class="text-base">{{ dateLogged }}</span><br>
              <span class="font-bold text-base">Utilisateurs : </span><span class="text-base">{{ countLogged }}</span>
            </div>

          </div>
        </div>
      </div>

      <div v-if="User !== null" class="w-full flex sm:flex-row flex-col gap-4">
        <div v-if="User.role != 'admin' && session != null"
          class="serie-progress sm:w-2/6 w-full text-center flex flex-col justify-center items-center p-2">
          <p class="">Votre derniere série</p>
          <h5 class=""> {{ session.name }} </h5>
          <div class="w-full p-2">
            <el-progress type="circle" :percentage="parseInt((session.answered / session.total) * 100)"
              :stroke-width="15" width="180" color="#f87171" />
          </div>
          <button v-if="Subscribed" @click="ResumeLastSession"
            class="py-2 px-5 mx-4 my-2 font-medium secondary-btn transition duration-500 transform-gpu hover:scale-110 ">
            Continuer la série
          </button>
          <button v-else disabled @click="ResumeLastSession"
            class="py-2 px-5 mx-4 my-2 text-white font-normal text-sm rounded-lg bg-gray-400 hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Continuer la série
          </button>
        </div>
        <div v-else-if="User.role != 'admin' && session == null"
          class="sm:w-2/6 w-full text-center flex flex-col justify-center items-center p-2">
          <el-result icon="info" title="Attention" subTitle="Vous n'avez pas encore de séries !">
          </el-result>
        </div>
        <div v-if="User.role != 'admin' && basicData.labels.length > 0"
          class="sm:w-4/6 w-full text-center flex justify-center items-center chart-wrapper">
          <div class=" w-full h-full card p-6">
            <h5 class="chart-wrapper__title mb-4">Les sept dernières séries</h5>
            <Chart v-if="basicData.labels.length > 0" type="line" :data="basicData" :options="basicOptions" />
          </div>
        </div>
        <div v-else-if="User.role != 'admin' && basicData.labels.length == 0"
          class="sm:w-4/6 w-full bg-white rounded-lg border shadow-lg text-center flex justify-center items-center">
          <el-result icon="info" title="Attention" subTitle="Vous n'avez pas encore de séries !">
          </el-result>

        </div>


        <div v-if="User.role == 'admin'"
          class="sm:w-1/4 w-full bg-white rounded-lg border shadow-lg sm:mr-4 ml-0 sm:mb-0 mb-4 text-center flex justify-center items-center">
          <p class="text-2xl mx-2">
            <span class="w-full flex justify-center">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                stroke="currentColor" class="h-8 w-8">
                <path
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
              </svg>
            </span>
            <span v-if="sessionsCount < 10" class="font-bold">0{{ sessionsCount }}</span><span v-else
              class="font-bold">{{ sessionsCount }}</span><br> Séries <br> ouvertes
          </p>

        </div>

        <div v-if="User.role == 'admin'"
          class="sm:w-1/4  w-full bg-white rounded-lg border shadow-lg sm:mr-4 ml-0 sm:mb-0 mb-4 text-center flex justify-center items-center">
          <p class="text-2xl mx-2">
            <span class="w-full flex justify-center">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                stroke="currentColor" class="h-8 w-8">
                <path d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
              </svg>
            </span>
            <span v-if="sessionsExamsCount < 10" class="font-bold">0{{ sessionsExamsCount }}</span><span v-else
              class="font-bold">{{ sessionsExamsCount }} </span><br> Examens <br> ouverts
          </p>
        </div>
        <div v-if="User.role == 'admin'"
          class="sm:w-2/4 w-full bg-white rounded-lg border shadow-lg mr-0 text-center flex justify-center items-center">
          <div class=" w-full h-full m-4 max-w-xl card">
            <h5 class="mt-2"> Nouveaux utilisateurs </h5>
            <Chart v-if="UserData.labels.length > 0" type="line" :data="UserData" :options="basicOptions" />
          </div>
        </div>

      </div>
    </div>

    <div :class="isLoading ? 'block' : 'hidden'"
      class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

  </div>

</template>

<script>
import { ElMessage } from 'element-plus';
import Chart from 'primevue/chart';
import axios from 'axios'
import QuestionStatIcon from '../icons/QuestionStatIcon.vue';
export default {
  components: {
    Chart,
    QuestionStatIcon
  },
  data() {
    return {
      currentRoute: window.location.pathname,
      year: 0,
      countQuestions: 0,
      countStudents: 0,
      countLogged: 0,
      dateLogged: '',
      modules: 0,
      session: {
        id: null,
        name: null,
        answers: 0,
        total: 0
      },
      isLoading: false,
      sessionsCount: 0,
      sessionsExamsCount: 0,
      basicData: {
        labels: [],
        datasets: [
          {
            label: 'Correctes',
            data: [],
            fill: false,
            borderColor: '#34D399',
            backgroundColor: '#34D399',
            tension: .4
          },
          {
            label: 'Fausses',
            data: [],
            fill: false,
            borderColor: '#F87171',
            backgroundColor: '#F87171',
            tension: .4
          },
          {
            label: 'Non répondues',
            data: [],
            fill: false,
            borderColor: "#D1D5DB",
            backgroundColor: '#D1D5DB',
            tension: .4
          }
        ]
      },
      UserData: {
        labels: [],
        datasets: [
          {
            label: 'Utilisateurs',
            data: [],
            fill: false,
            borderColor: '#F87171',
            backgroundColor: '#F87171',
            tension: .4
          },
        ]
      },

      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: this.dark ? '#FFFFFFDE': '#000000'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: this.dark ? '#FFFFFFDE': '#000000'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: this.dark ? '#FFFFFFDE': '#000000'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
    }
  },

  props: {
    linkPath: Function,
    dark: Boolean
  },

  mounted() {
    this.linkPath(this.currentRoute);
    this.year = this.$store.getters.get_subscribeNow;
    this.isLoading = true;
    if (this.User.role == 'etudiant') {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/getcountquestionyear/" + this.year, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.countQuestions = data.result;
          this.modules = data.modules;
          this.isLoading = false;
        })
        .catch(() => {

          this.errorm();

          this.isLoading = false;
        });

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/lastsessionS/" + this.User.id + "/" + this.SubscribeNow, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {

          this.session = data.result;
          this.isLoading = false;
        })
        .catch(() => {

          this.errorm();
          this.isLoading = false;
        });

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/lastsevensession/" + this.User.id, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          let i = 0;
          data.result.forEach(element => {
            this.basicData.labels[i] = element.name;
            this.basicData.datasets[0].data[i] = element.noteData.true
            this.basicData.datasets[1].data[i] = element.noteData.false
            this.basicData.datasets[2].data[i] = element.noteData.unanswered
            i++;
          })
          this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });

    }

    if (this.User.role == 'admin') {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/students", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.countStudents = data.students;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/loggedtoday", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.countLogged = data.result;
          this.dateLogged = data.today;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/sessionscount", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          data.result.forEach(element => {
            if (element.isExam === '0') {
              this.sessionsCount = element.count;
            } else if (element.isExam === '1') {
              this.sessionsExamsCount = element.count;
            }

          })

          this.isLoading = false;
        })
        .catch((err) => {

          this.errorm();
          this.isLoading = false;
        });

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/lastweekuserscount", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          let i = data.result.length - 1;
          data.result.forEach(element => {
            this.UserData.labels[i] = element.date;
            this.UserData.datasets[0].data[i] = element.count
            i--;
          })
          this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });
    }

  },


  methods: {
    ResumeLastSession() {
      this.$router.push("/dashboard/currentSession/?id=" + this.session.id);
    }
  },
  computed: {
    User() {
      return this.$store.getters.get_user;
    },
    isToken() {
      return this.$store.getters.get_token;
    },
    SubscribeNow() {
      return this.$store.getters.get_subscribeNow
    },
    Subscribed() {
      return this.$store.getters.get_subscriptions.includes(String(this.$store.getters.get_subscribeNow))
    },
  },

  setup() {
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return { errorm }
  }

}
</script>

<style></style>